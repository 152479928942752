<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  async created() {},
  methods: {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;700;900&display=swap");
@import "../../../../node_modules/font-awesome/css/font-awesome.min.css";

body {
  background-color: #ecebea;
  color: #383838;
  font-family: "Noto Sans TC", sans-serif;
  font-weight: 400;
  background: url(images/survey-bg.jpg);
  background-repeat: repeat;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
}

.h5,
h5 {
  font-size: 1.15rem;
}

.font-weight-bolder {
  font-weight: 700;
}
.font-weight-bold {
  font-weight: 900;
}

.text-secondary{
  color: #383838 !important;
}

.button {
  background-color: #e3e3e3;
  padding: 0.2rem;
  font-size: 1.5rem;
  border: none;

  font-weight: 900;
  border-radius: 0.5rem;
  display: block;
  width: 100%;

  > .wrapper {
    border: solid 1px white;
    padding: 0.5rem;
    border-radius: 0.4rem;

    .small {
      font-size: 0.9rem;
    }
  }

  &.active {
    color: white;
    background-color: #cc2d28;
  }

  &.gold {
    color: white;
    background: rgb(196, 150, 95);
    background: linear-gradient(
      70deg,
      rgba(196, 150, 95, 1) 0%,
      rgba(255, 206, 143, 1) 54%,
      rgba(254, 217, 172, 1) 55%,
      rgba(244, 207, 163, 1) 70%,
      rgba(234, 184, 123, 1) 73%,
      rgba(196, 150, 95, 1) 100%
    );

    &:active,
    &:hover {
      color: white;
    }

    > .wrapper {
      text-shadow: 2px 2px 5px rgba(125, 88, 43, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 1.8rem;
        margin-left: 0.5rem;
      }
    }

    &.animate {
      animation: gradient 3s ease infinite;
      background-size: 200%;

      @keyframes gradient {
        0% {
          background-position: 0% 50%;
        }        
        100% {
          background-position: 200% 50%;
        }
      }
    }
  }
}

img {
  max-width: 100%;
}

.bg-danger {
  background-color: #cc2d28 !important;
}

.text-danger {
  color: #cc2d28 !important;
}

.card {
  border: none;
}
</style>
